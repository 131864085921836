<template>
  <div class="preview-report-popup">
    <b-tabs content-class="mt-3">
      <b-tab title="For Slack" active>
        <div v-if="for_slack.loading" class="loader"></div>
        <div v-else class="report" v-html="for_slack.report"></div>
        <b-btn
          variant="primary"
          class="border-0 border-bottom rounded-0"
          @click="sendReport('slack')"
          :disabled="for_slack.sendButtonDisabled"
        >{{ for_slack.sendButtonText }}</b-btn>
      </b-tab>
      <b-tab title="For Email">
        <div v-if="for_email.loading" class="loader"></div>
        <div v-else class="report" v-html="for_email.report"></div>
        <b-btn
          variant="primary"
          class="border-0 border-bottom rounded-0"
          @click="sendReport('email')"
          :disabled="for_email.sendButtonDisabled"
        >{{ for_email.sendButtonText }}</b-btn>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'

export default {
  data() {
    return {
      for_slack: {
        report: '',
        sendButtonText: 'Send to Slack',
        sendButtonDisabled: false,
        loading: true
      },
      for_email: {
        report: '',
        sendButtonText: 'Send to Email',
        sendButtonDisabled: false,
        loading: true
      },
    }
  },
  methods: {
    formattedTextForSlack(message) {
      let formatted = message;

      const regex = /<(https?:\/\/[^\s|]+)\|([^>]+)>/g;
      formatted = formatted.replace(regex, (match, url, text) => {
        return `<a href="${url}" target="_blank">${text}</a>`;
      });

      // Регулярное выражение для поиска звездочек и преобразования их в <strong>
      formatted = formatted.replace(/\*(.*?)\*/g, '<strong>$1</strong>');

      // Регулярное выражение для поиска ссылок после NOTE: и RESULT LINK:
      const noteRegex = /NOTE:\s*(https?:\/\/[^\s]+)/g;
      const resultLinkRegex = /RESULT LINK:\s*(https?:\/\/[^\s]+)/g;

      formatted = formatted.replace(noteRegex, (match, url) => {
        return `NOTE: <a href="${url}" target="_blank">${url}</a>`;
      });

      formatted = formatted.replace(resultLinkRegex, (match, url) => {
        return `RESULT LINK: <a href="${url}" target="_blank">${url}</a>`;
      });

      // Удаление лишних переносов строк
      formatted = formatted.replace(/\n{3,}/g, '\n\n');

      return formatted;
    },
    formattedTextForEmail(report) {
      let formatted = '';

      formatted += `<strong>To:</strong> ${report.to}\n`;
      formatted += `<strong>Cc:</strong> ${report.cc}\n`;
      formatted += `<strong>Subject:</strong> ${report.subject}\n`;
      formatted += `<strong>Body:</strong> ${report.body}\n\n`;

      const regex = /<(https?:\/\/[^\s|]+)\|([^>]+)>/g;
      formatted = formatted.replace(regex, (match, url, text) => {
        return `<a href="${url}" target="_blank">${text}</a>`;
      });

      // Регулярное выражение для поиска звездочек и преобразования их в <strong>
      formatted = formatted.replace(/\*(.*?)\*/g, '<strong>$1</strong>');

      // Регулярное выражение для поиска ссылок после NOTE: и RESULT LINK:
      const noteRegex = /NOTE:\s*(https?:\/\/[^\s]+)/g;
      const resultLinkRegex = /RESULT LINK:\s*(https?:\/\/[^\s]+)/g;

      formatted = formatted.replace(noteRegex, (match, url) => {
        return `NOTE: <a href="${url}" target="_blank">${url}</a>`;
      });

      formatted = formatted.replace(resultLinkRegex, (match, url) => {
        return `RESULT LINK: <a href="${url}" target="_blank">${url}</a>`;
      });

      // Удаление лишних переносов строк
      formatted = formatted.replace(/\n{3,}/g, '\n\n');
      return formatted;
    },
    async sendReport(type) {
      const buttonText = this[`for_${type}`].sendButtonText;
      try {
        this[`for_${type}`].sendButtonText = 'Sending...';
        this[`for_${type}`].sendButtonDisabled = true;
        const response = await MtApi.clientReport({ client_id: this.$attrs.params.data._id, type });
        const updated = this.$attrs.params.node.data;
        for (const res_key in response) {
          updated[res_key] = response[res_key];
        }
        this.$attrs.params.node.updateData(updated);
        this[`for_${type}`].sendButtonText = buttonText;
        this[`for_${type}`].sendButtonDisabled = false;
      } catch (err) {
        this[`for_${type}`].sendButtonText = buttonText;
        this[`for_${type}`].sendButtonDisabled = false;
      }
    }
  },
  async mounted() {
    try {
      this.sendButtonDisabled = true;
      const response = await MtApi.clientReport({ client_id: this.$attrs.params.data._id, preview: true });
      // For slack
      this.for_slack.report = this.formattedTextForSlack(response.report.for_slack);
      this.for_slack.loading = false;
      this.for_slack.sendButtonDisabled = false;
      // For email
      this.for_email.report = this.formattedTextForEmail(response.report.for_email);
      this.for_email.loading = false;
      this.for_email.sendButtonDisabled = false;
    } catch (err) {
      this.sendButtonDisabled = true;
      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-report-popup {
  margin: 25px;
  overflow: auto;
  .report {
    white-space: pre-wrap;
  }
  .loader {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #7a85ff;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
    margin: 50px auto;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
</style>