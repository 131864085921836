<template>
  <div class="clients-settings-page">
    <settings-navigation />
    <div class="agGrid-page container">
      <base-header>
        <show-archived-addon />
      </base-header>
      <div v-html="customHtml"></div>
      <base-ag-grid
        :columnDefs="columnDefs"
        :datasource="datasource"
        :cellValueChanged="cellValueChanged"
      />
    </div>
  </div>
</template>

<script>
// Components
import BaseAgGrid from '@/agGridV2/components/base.component.vue'
import SettingsNavigation from '@/components/SettingsNavigation.vue'
import BaseHeader from '@/agGridV2/components/header/header.component.vue'
import ShowArchivedAddon from '@/agGridV2/components/header/show-archived.component.vue'
import PreviewReportPopup from '@/agGridV2/components/preview-report-popup.component.vue'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
// Custom Cell Renderer
import ProjectsSelectRenderer from '@/agGridV2/renderers/projects-select.renderer.vue'
import SelectRenderer from '@/agGridV2/renderers/select.renderer.vue'
import SimpleSelectRenderer from '@/agGridV2/renderers/simple-select.renderer.vue'
import CheckboxRenderer from '@/agGridV2/renderers/checkbox.renderer.vue'

export default {
  components: {
    BaseAgGrid,
    // eslint-disable-next-line vue/no-unused-components
    ProjectsSelectRenderer,
    // eslint-disable-next-line vue/no-unused-components
    SelectRenderer,
    // eslint-disable-next-line vue/no-unused-components
    SimpleSelectRenderer,
    // eslint-disable-next-line vue/no-unused-components
    CheckboxRenderer,
    SettingsNavigation,
    BaseHeader,
    ShowArchivedAddon,
    // eslint-disable-next-line vue/no-unused-components
    PreviewReportPopup
  },
  data() {
    return {
      datasource: null,
      columnDefs: null,
      customHtml: null,
      cellValueChanged: null
    }
  },
  async mounted() {
    const [projects, staffs] = await Promise.all([
      MtApi.getProjects(),
      MtApi.getStaffsByActivityTag()
    ])
    this.customHtml = this.customHtmlGenerate(projects.notDistributedProjects)
    this.columnDefs = [
      {
        field: 'name',
        headerName: 'Client Name (QB)',
        width: 300
      },
      {
        field: 'projects',
        headerName: 'Projects',
        cellRenderer: 'ProjectsSelectRenderer',
        cellRendererParams: {
          values: projects.all
        },
        cellStyle: { overflow: 'visible' },
        width: 300,
        filter: null
      },
      {
        field: 'created_date',
        headerName: 'Created Date',
        valueFormatter: (params) => {
          return SimpleHelper.dateFormatter(params.value)
        },
        filter: 'agDateColumnFilter'
      },
      {
        field: 'full_total',
        headerName: 'Total Revenue (from January 1, 2021)',
        cellRenderer: (params) => {
          let html = '<div>'
          html += SimpleHelper.priceFormatter(params.value, true)
          if (params.data.unpaid_total) {
            html += `<span style="color: #FF3D34"> (${SimpleHelper.priceFormatter(
              params.data.unpaid_total
            )})</span>`
          }
          html += '</div>'
          return html
        },
        filter: 'agNumberColumnFilter'
      },
      // {
      //   field: 'am_commission',
      //   headerName: 'AM Commission (%)',
      //   filter: null,
      //   editable: true,
      //   cellRenderer: (params) => {
      //     if (params.value) {
      //       return params.value + '%'
      //     }
      //     return '<div class="commission-placeholder">4.5%</div>'
      //   }
      // },
      {
        field: 'am',
        headerName: 'AM',
        cellRenderer: 'SelectRenderer',
        cellRendererParams: {
          selectDefaultLabel: 'Select AM',
          options: staffs.am
        },
        cellStyle: { overflow: 'visible' },
        width: 300,
        filter: null
      },
      {
        field: 'am_supervisor',
        headerName: 'AM supervisor',
        cellRenderer: 'SelectRenderer',
        cellRendererParams: {
          selectDefaultLabel: 'Select AM Supervisor',
          options: staffs.am
        },
        cellStyle: { overflow: 'visible' },
        width: 300,
        filter: null
      },
      {
        field: 'pm',
        headerName: 'PM',
        cellRenderer: 'SelectRenderer',
        cellRendererParams: {
          selectDefaultLabel: 'Select PM',
          options: staffs.pm
        },
        cellStyle: { overflow: 'visible' },
        width: 300,
        filter: null
      },
      {
        field: 'ae',
        headerName: 'AE',
        cellRenderer: 'SelectRenderer',
        cellRendererParams: {
          selectDefaultLabel: 'Select AE',
          options: staffs.ae
        },
        cellStyle: { overflow: 'visible' },
        width: 300,
        filter: null
      },
      {
        field: 'last_paid_invoice_date',
        headerName: 'Last Paid Invoice Date',
        valueFormatter: (params) => {
          return SimpleHelper.dateFormatter(params.value)
        },
        filter: 'agDateColumnFilter'
      },
      {
        field: 'status',
        headerName: 'Status',
        // cellRenderer: 'SimpleSelectRenderer',
        // cellRendererParams: {
        //   options: [
        //     {
        //       label: 'Active',
        //       value: 'active'
        //     },
        //     {
        //       label: 'Archived',
        //       value: 'archived'
        //     }
        //   ]
        // },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['active', 'archived'],
          valueFormatter: (params) => {
            if (params.value === 'active') {
              return 'Active'
            } else if (params.value === 'archived') {
              return 'Archived'
            }
            return params.value
          }
        }
      },
      {
        field: 'note',
        tooltipField: 'note',
        headerName: 'Note',
        editable: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          maxLength: 100,
          rows: 10,
          cols: 50
        },
        filter: null
      },
      {
        field: 'old',
        headerName: 'Old/Unnecessary client',
        cellRenderer: 'CheckboxRenderer',
        filter: null
      },
      {
        field: 'email',
        headerName: 'Email',
        valueFormatter: (params) => {
          if (!params.value) {
            return ''
          }
          const emails = params.value.split(', ')
          if (emails && emails.length > 0) {
            return emails[0]
          }
          return params.value
        }
      },
      {
        field: 'public_password',
        headerName: 'Public Password'
      },
      {
        field: 'report_brand',
        headerName: 'Brand',
        cellRenderer: 'SimpleSelectRenderer',
        cellRendererParams: {
          options: [
            {
              label: 'Comrade Digital Marketing',
              value: {
                link: 'https://comradeweb.com',
                label: 'Comrade Digital Marketing',
                from: 'info@comradeweb.com'
              }
            },
            {
              label: 'Grow Law Firm',
              value: {
                link: 'https://growlawfirm.com',
                label: 'Grow Law Firm',
                from: 'info@growlawfirm.com'
              }
            }
          ]
        }
      },
      {
        field: 'preview_report',
        headerName: 'Preview Report',
        cellRenderer: (params) => {
          const btn = document.createElement('button')
          btn.textContent = 'Preview Report'
          btn.className = 'button-outline-blue'
          btn.addEventListener('click', async () => {
            this.$modal.show(
              PreviewReportPopup,
              {
                params
              },
              {
                width: '1200',
                height: 'auto',
                classes: ['preview-report-popup-wrapper']
              }
            )
          })
          return btn
        },
        editable: false
      },
      {
        field: 'report_names',
        headerName: 'Report Names',
        editable: true,
        valueSetter: (params) => {
          const value = params.newValue
          if (value && value.includes(',')) {
            const elements = value.split(',')
            const trimmedElements = elements.map((element) => element.trim())
            params.data.report_names = trimmedElements.join(', ')
            return true
          } else {
            params.data.report_names = value
            return true
          }
        }
      },
      {
        field: 'report_emails',
        headerName: 'Report Emails',
        editable: true,
        valueSetter: (params) => {
          const value = params.newValue
          if (value && value.includes(',')) {
            const elements = value.split(',')
            const trimmedElements = elements.map((element) => element.trim())
            params.data.report_emails = trimmedElements.join(', ')
            return true
          } else {
            params.data.report_emails = value
            return true
          }
        }
      },
      {
        field: 'report_channel',
        headerName: 'Report Channel',
        editable: true
      },
      {
        field: 'report_day',
        headerName: 'Report Day',
        cellRenderer: 'SimpleSelectRenderer',
        cellRendererParams: {
          options: [
            {
              label: 'Monday',
              value: 'monday'
            },
            {
              label: 'Tuesday',
              value: 'tuesday'
            },
            {
              label: 'Wednesday',
              value: 'wednesday'
            },
            {
              label: 'Thursday',
              value: 'thursday'
            },
            {
              label: 'Friday',
              value: 'friday'
            },
            {
              label: 'Saturday',
              value: 'saturday'
            },
            {
              label: 'Sunday',
              value: 'sunday'
            }
          ]
        }
      },
      {
        field: 'report_last_send_date',
        headerName: 'Report Last Send Date',
        valueFormatter: (params) => {
          return SimpleHelper.dateFormatter(params.value)
        },
        filter: 'agDateColumnFilter'
      }
    ]
    this.datasource = {
      getRows: (params) => {
        params.request['search'] = this.$store.getters.getSearchQuery(
          this.$route.name
        )
        params.request['showArchived'] = this.$store.getters.getShowArchived(
          this.$route.name
        )
        MtApi.agGridClientsSettings(params.request).then((response) => {
          this.$store.dispatch('setAgGridRowsCount', {
            page: this.$route.name,
            query: response.count
          })
          this.$emitter.emit('set_agGrid_rows_count', {})
          params.success({ rowData: response.rows, rowCount: response.count })
        })
      }
    }
    this.cellValueChanged = async (params) => {
      const data = {
        event: 'update_client',
        field: {
          name: params.colDef.field,
          value: params.value
        }
      }
      await MtApi.updateClient(params.data._id, data)
      if (params.colDef.field === 'projects') {
        const projects = await MtApi.getProjects()
        this.customHtml = this.customHtmlGenerate(
          projects.notDistributedProjects
        )
      }
    }
  },
  methods: {
    customHtmlGenerate(projects) {
      let htmlContent = ''
      if (projects && projects.length > 0) {
        htmlContent += '<div class="all_pft_projects">'
        projects.forEach((project) => {
          htmlContent += `<div>${project.name}</div>`
        })
        htmlContent += '</div>'
      }
      htmlContent += ''
      return htmlContent
    }
  }
}
</script>
